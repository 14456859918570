import React, { Component } from 'react'
import SEO from '../seo'
import CookieSimple from '../cookie/cookieSimple'
import Footer from '../footer'
import { Helmet } from "react-helmet"
// const pageDescription = 'Behavioral Economics in Marketing works. And this is the only online Masterclass there is on it. The Next Big Thing in marketing which can 3x, 5x, 7x your results.'
import "../../styles/colors.scss"
import "../../styles/responsive.scss"
import "../../styles/global.scss"

const MainLayout = ({children, ...rest}) => {
  return (
    <div {...rest}>
      <SEO />

      {children}

      <CookieSimple />
    </div >
  )
}

export default MainLayout
