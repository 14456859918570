import React, { Component } from 'react'
import Button from '../button'
import styles from "./cookieSimple.module.css"
import Cookies from 'js-cookie'
import Drift from '../Drift'
const acceptButtonStyle = { fontWeight: 'bold', display: 'block', margin: '10px auto' }

export default class cookieSimple extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      loadGDPRDepedencies: false,
      // default is true due to compatibility
      loadDrift: this.props.dontLoadDrift ? false : true
    }
    this.handleAcceptButton = this.handleAcceptButton.bind(this)
  }

  componentDidMount() {
    if (Cookies.get('gdpr_cookie') !== undefined) {
      this.setState({ loadGDPRDepedencies: true })
      return
    } else {
      this.setState({ isOpen: true })
    }
  }

  /**
   * Handle user's cookie permission
   */
  handleAcceptButton() {
    this.setState({ isOpen: false, loadGDPRDepedencies: true })
    Cookies.set('gdpr_cookie', true)
  }

  render() {
    const shouldBeDriftLoaded = this.state.loadGDPRDepedencies && this.state.loadDrift

    return (
      <div className={styles.wrapper} style={{ display: this.state.isOpen ? 'block' : 'none' }}>
        <div className={styles.content}>
          <div className={styles.col2}>
            <div>
              We are using cookies for proper functioning, performance, social media and advertising purposes.
              Social media and advertising cookies of third parties are used to offer you social media functionalities and personalized ads.
              To get more information or amend your preferences, click this <a href="#">link</a>.
            </div>
            <div>
              <Button
                onClick={this.handleAcceptButton}
                style={acceptButtonStyle}
              >
                YES, I ACCEPT
              </Button>
            </div>
          </div>
        </div>

        {/* HERE, WE CAN LOAD GPDR DEPENDENT COMPONENTS */}
        {shouldBeDriftLoaded && <Drift />}
      </div>
    )
  }
}
